import './App.css';

function App() {
  return (
    <div className='background'>
      <span className='placeholder-text'> Quinn Sieja - Coming Soon </span>
    </div>
  );
}

export default App;
